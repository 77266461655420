import React, { FC, MouseEvent } from 'react';
import cn from 'classnames'
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import css from './LottieNews.module.scss'
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import ArticleMedia from "../../Article/ArticleMedia";
import {ROUTE} from "../../../constants";
import {setLoginOpen} from "../../../store/reducers/ModalSlice";
import {analyticsArticle} from "../../../utils/analytics";

const LottieNews: FC = () => {
    const { isLoading, floor } = useAppSelector(state => state.floor);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    if(!floor){
        return <div className={cn(css.news, css.minHeight)}>

        </div>
    }


    const { article_title, article_id, article_lead, medias } = floor[0];

    const onClick = (e: MouseEvent) => {
        e.preventDefault()
        navigate(ROUTE.ARTICLE + article_id);
        dispatch(setLoginOpen(true));

        analyticsArticle();
    };

    return (
        <div className={css.news}>
            <div className={css.title} onClick={onClick}>
                {article_title}
            </div>
            {medias && medias.length
                ? <div className={css.media}>
                    <ArticleMedia media={medias}/>
                </div>
                : null
            }
            <div className={css.announce} dangerouslySetInnerHTML={{__html: article_lead}}/>
            <Link href={ROUTE.ARTICLE + article_id} className={css.link} onClick={onClick}>Читать дальше</Link>
            <div className={css.blink}>
                <div className={css.blinkIcon}/>
            </div>
        </div>
    )
}

export default LottieNews