import React, {FC} from 'react';
import cn from 'classnames'
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {A11y, EffectFade, Navigation, Pagination} from "swiper";
import widget1 from "../../lottie/images/widget1.webm";
import widget2 from "../../lottie/images/widget2.webm";
import widget3 from "../../lottie/images/widget3.webm";
import widget4 from "../../lottie/images/widget4.webm";
import widget5 from "../../lottie/images/widget5.webm";
import widget6 from "../../lottie/images/widget6.webm";
import widget8 from "../../lottie/images/widget8.webm";
import css from './Lottie.module.scss';
import Icon from "../Icon";
import {ICON_VARIANT} from "../../assets/svg";
import {useAppSelector} from "../../hooks/redux";
import {SCREEN_VARIANT} from "../../types/IScreen";
import {THEME} from "../../types/IUser";
import {SUBSCRIBE, TELEGRAM} from "../../constants";
import LottieNews from "./LottieNews";
import LottieTelegram from "./LottieTelegram";
import {useTranslate} from "../../hooks/useTranslate";
import LottieVideo from "./LottieVideo";

SwiperCore.use([EffectFade, Navigation, Pagination, A11y]);

const LottiePage:FC = () => {
    const { lang } = useAppSelector(state => state.auth);
    const windowScreen = useAppSelector(state => state.screen.screen);
    const {theme} = useAppSelector(state => state.auth);
    const translate = useTranslate();

    return <>
        <div className={css.relative}>
            <div className={cn(css.section, css.main)}>
                <div className={css.info}>
                    <div className={css.title}>
                        Мультимедийный новостной терминал
                    </div>
                    <div className={css.description}>
                        Выбор из оперативных и архивных новостей, фото и видео на единой мультимедийной платформе
                    </div>
                    <div className={css.buttons}>
                        <a href={SUBSCRIBE[lang].link} target={"_blank"} className={css.btn}>Оформить подписку</a>
                        <a href={TELEGRAM[lang].link} target={"_blank"} className={cn(css.btn, css.outline)}><span
                            className={css.btnIcon}><Icon
                            name={ICON_VARIANT.TELEGRAM}/></span>Читать в Телеграм</a>
                    </div>
                </div>
                <div className={css.media}>
                    <div className={css.macbook}>
                        <div className={css.lottieNews}>
                            <LottieNews/>
                        </div>
                        <div className={css.lottieTelegram}>
                            <LottieTelegram/>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className={css.ellipse_1}/>*/}
            {/*<div className={css.ellipse_2}/>*/}
        </div>
        <div className={cn(css.section, css.reverse)}>
            <div className={css.info}>
                <div className={css.title}>
                    Управление контентом
                </div>
                <div className={css.description}>
                    Вам доступны группировка новостей по темам и сюжетам, сложный поиск по фразам, настраиваемые фильтры
                    и избранное
                </div>
            </div>
            <div className={css.media}>
                <LottieVideo video={widget1}/>
            </div>
        </div>
        <div className={css.section}>
            <div className={css.info}>
                <div className={css.title}>
                    Картина дня от редакции
                </div>
                <div className={css.description}>
                    Самые главные новости часа, актуальные тенденции дня в мультимедийном формате
                </div>
            </div>
            <div className={css.media}>
                <LottieVideo video={widget2}/>
            </div>
        </div>
        <div className={cn(css.section, css.reverse)}>
            <div className={css.info}>
                <div className={css.title}>
                Управление новостями
                </div>
                <div className={css.description}>
                    Возможность делиться новостями в соцсетях и мессенджерах. История скачиваний сохраняется
                </div>
            </div>
            <div className={css.media}>
                <LottieVideo video={widget3} />
            </div>
        </div>
        <div className={css.section}>
            <div className={css.info}>
                <div className={css.title}>
                    Мультимедийный контент
                </div>
                <div className={css.description}>
                    Впервые для подписчиков доступна синергия текста, фото и видео в новости
                </div>
            </div>
            <div className={css.media}>
                <LottieVideo video={widget4} />
            </div>
        </div>
        <div className={cn(css.section, css.reverse)}>
            <div className={css.info}>
                <div className={css.title}>
                    Обучаемый поиск
                </div>
                <div className={css.description}>
                    Для формирования релевантной выдачи.
                    Возможность создания собственных лент
                </div>
            </div>
            <div className={css.media}>
                <LottieVideo video={widget5} />
            </div>
        </div>
        <div className={css.section}>
            <div className={css.info}>
                <div className={css.title}>
                    Доступность на мобильных
                </div>
                <div className={css.description}>
                    Адаптированная версия с полным функционалом для мобильных устройств
                </div>
            </div>
            <div className={css.media}>
                <LottieVideo video={widget6}/>
            </div>
        </div>

        <div className={cn(css.section, css.tag)}>
            <div className={css.title}>
                Больше преимуществ в новой версии
            </div>
            <div className={css.slider}>
                <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={SCREEN_VARIANT.DESKTOP === windowScreen ? 24 : 16}
                    speed={300}
                >
                    <SwiperSlide>
                        <div className={css.slide}>
                            <div className={css.slideIcon}><Icon name={ICON_VARIANT.MOBILE}/></div>
                            <div className={css.slideTitle}>Больше контента</div>
                            <div className={css.slideDescription}>Впервые в истории — доступ для читателей к части
                                закрытого контента
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={css.slide}>
                            <div className={css.slideIcon}><Icon name={ICON_VARIANT.MESSAGE}/></div>
                            <div className={css.slideTitle}>Интеграция</div>
                            <div className={css.slideDescription}>Интеграция с новым Telegram-ботом и Медиабанком РИА
                                Новости
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={css.slide}>
                            <div className={css.slideIcon}><Icon name={ICON_VARIANT.MANUAL}/></div>
                            <div className={css.slideTitle}>Руководство</div>
                            <div className={css.slideDescription}>Подробная справка по функционалу с анимированными
                                иллюстрациями
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
        <div className={cn(css.section, css.news)}>
            <div className={css.title}>
                Новостные ленты — поток новостей из любой точки мира
            </div>
            <div className={css.newsList}>
                <div className={css.newsItem}>
                    <div className={css.newsTitle}>
                        На русском языке
                    </div>
                    <div className={css.newDescription}>
                        1800+ новостей в сутки на русском языке 24/7 от корреспондентской сети в 120+ городах
                    </div>
                </div>
                <div className={css.newsItem}>
                    <div className={css.newsTitle}>
                        На других языках
                    </div>
                    <div className={css.newDescription}>
                        1300+ новостей в сутки на английском, арабском, фарси, испанском и китайском языках 24/7
                    </div>
                </div>
            </div>
            <div className={css.newsBtn}>
                <a href={'/'} className={cn(css.btn, css.light)}>Узнать подробнее</a>
            </div>
        </div>
        <div className={cn(css.section, css.newsMedia)}>
            <div className={css.newsMediaWrapper}>
                <div className={css.newsMediaImg}>
                    <LottieVideo video={widget8}/>
                    {/*<img src={widget8} alt=""/>*/}
                </div>
            </div>
        </div>

        <div className={css.footer}>
            <div className={css.footerWrapper}>
                <div className={css.footerLogo}>
                    {theme === THEME.RIA
                        ? <Icon name={ICON_VARIANT.LOGO}/>
                        : <Icon name={ICON_VARIANT.LOGO_SPUTNIK}/>
                    }
                </div>
                <div className={css.footerInfo}>
                    <div className={css.footerList}>
                        {translate.footer.tags.map((item, i) =>
                            <a key={i} href={item.link} className={css.footerListItem}>{item.title}</a>
                        )}
                    </div>
                    <div className={css.copyright}>© 2025 МИА «Россия сегодня»</div>
                </div>
            </div>
        </div>
    </>
}

export default LottiePage