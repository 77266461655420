import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import {TELEGRAM} from "../../../constants";
import css from './LottieTelegram.module.scss';
import {useAppSelector} from "../../../hooks/redux";

const LottieTelegram:FC = () => {
    const {lang} = useAppSelector(state => state.auth);
    const unix = moment().unix();
    const widget = TELEGRAM[lang].widget;
    const [url, setUrl] = useState<string>(widget + '?v=' + unix);

    useEffect(() => {
        const timer = setInterval(() => {
            const unix = moment().unix();
            const url = widget + '?v=' + unix;
            setUrl(url);
        }, 1000 * 60);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <div className={css.telegram}>
            <iframe src={url} frameBorder="0"/>
        </div>
    )
}

export default LottieTelegram
