import React, {FC, useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";

interface PropsLottieVideo {
    video: any
    maxWidth?: number
}

const LottieVideo:FC<PropsLottieVideo> = ({video, maxWidth}) => {
    const [hasAppeared, setHasAppeared] = useState(false); // Состояние первого появления
    const videoRef = useRef(null); // Ссылка на видео элемент
    const elementRef = useRef(null); // Ссылка на контейнер

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !hasAppeared) {
                    setHasAppeared(true); // Устанавливаем состояние первого появления
                }
            },
            { threshold: 0.5 } // Порог видимости: 50%
        );

        if (elementRef.current) {
            observer.observe(elementRef.current); // Наблюдаем за элементом
        }

        return () => {
            if (elementRef.current) {
                observer.unobserve(elementRef.current); // Отписываемся от наблюдения
            }
        };
    }, [hasAppeared]);

    useEffect(() => {
        if (hasAppeared && videoRef.current) {
            videoRef.current.play(); // Запускаем видео при первом появлении
        }
    }, [hasAppeared]);

    return (
        <motion.div
            ref={elementRef}
            initial={{ opacity: 0, scale: 0.8 }} // Начальные значения
            animate={hasAppeared ? { opacity: 1, scale: 1 } : {}} // Анимация появления
            transition={{ duration: 0.8 }} // Длительность анимации
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: '100%',
                maxWidth: maxWidth ? maxWidth + 'px' : null
            }}
        >
            {hasAppeared && (
                <video
                    ref={videoRef}
                    src={video} // Укажите путь к вашему видео
                    autoPlay={false} // Автозапуск отключен, чтобы контролировать вручную
                    loop // Видео воспроизводится зацикленно
                    muted // Отключаем звук
                    playsInline // Позволяет воспроизводить видео внутри страницы
                    controls={false}
                    style={{ display: 'block', width: '100%', height: 'auto' }} // Размер видео
                />
            )}
        </motion.div>
    );
}

export default LottieVideo